// routerM.js
export const routerM = [
  {
    path: "/",
    component: () => import("@/views/mobile/index.vue"),
    hidden: true,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/mobile/home.vue"),
        meta: {
          title: "首页",
          icon: "home",
        },
      },
      {
        path: "protocol",
        name: "Protocol",
        component: () => import("@/views/mobile/protocol.vue"),
        meta: {
          title: "协议",
          icon: "protocol",
        },
      },
      {
        path: "aboutUs",
        name: "AboutUs",
        component: () => import("@/views/mobile/aboutUs.vue"),
        meta: {
          title: "关于我们",
          icon: "aboutUs",
        },
      },
      {
        path: "contactUs",
        name: "ContactUs",
        component: () => import("@/views/mobile/contactUs.vue"),
        meta: {
          title: "联系我们",
          icon: "contactUs",
        },
      },
    ],
  },
];
