<template>
  <div class="Protocol">
    <div class="section-wrap">
      <div class="box-one">
        <div
          @click="changeTab(0)"
          class="tab-item"
          :style="{ color: tabIndex == 0 ? '#00B386' : '#333333' }"
        >
          <span>隐私政策</span>
          <div class="line" v-if="tabIndex == 0"></div>
        </div>
        <div
          @click="changeTab(1)"
          class="tab-item"
          :style="{ color: tabIndex == 1 ? '#00B386' : '#333333' }"
        >
          <span>用户协议</span>
          <div class="line" v-if="tabIndex == 1"></div>
        </div>
      </div>
      <div class="box-two">
        <div class="title">{{ tabIndex == 0 ? "隐私政策" : "用户协议" }}</div>
        <iframe v-if="tabIndex==0" class="iframe-box" src="https://qiniuyun.shanxinlife.com/agreement/隐私政策.html" frameborder="0"></iframe>
        <iframe v-if="tabIndex==1" class="iframe-box" src="https://qiniuyun.shanxinlife.com/agreement/用户协议.html" frameborder="0"></iframe>
        <!-- <div class="content-box">
          我们将通过本隐私政策向你介绍不同场景下我们如何处理个人信息。当你开启或使用抖音时，为实现你选择使用的功能、服务，或为遵守法律法规的要求，我们会处理相关信息。除实现抖音基本功能、服务所需的信息，和根据法律法规要求所必需的信息之外，你可以拒绝我们处理其他信息，但这可能导致我们无法提供对应功能、服务。我们将在隐私政策中逐项说明相关情况，有关你个人信息权益的重要条款已用加粗形式提示，请特别关注。
          抖音及抖音各关联版本之间的实际功能可能略有差异，但如关联版本无独立隐私政策，我们会遵循本隐私政策收集、处理你的个人信息。如果抖音的关联版本有独立隐私政策的，则关联版本的隐私政策同时适用。本隐私政策以及该关联版本隐私政策二者不一致的，适用关联版本的隐私政策。
          除本隐私政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向你说明对应的信息收集目的、范围及使用方式，这些即时告知。
          <br />
          下文将帮你详细了解我们如何收集、使用、存储、传输、公开与保护个人信息；帮你了解查询、更正、补充、删除、复制、转移个人信息的方式。其中，有关你个人信息权益的重要内容已用加粗形式提示，请特别关注。
          <br />
          1.我们如何收集和使用个人信息 <br />
          2.数据使用过程中涉及的合作方以及转移、公开个人信息 <br />
          3.管理你的个人信息 <br />
          4.我们如何保护个人信息的安全 <br />
          5.我们如何存储个人信息 <br />
          6.我们如何保护未成年人 <br />
          7.隐私政策的查阅和修订 <br />
          8.联系我们 <br />
          1 我们如何收集和使用个人信息 <br />
          1.1 账号服务 <br />
          1.1.1 注册、登录 <br />
          当你注册、登录抖音及使用相关服务时，你可以通过手机号码创建账号，并且你可以完善相关的网络身份识别信息（抖音号、头像、昵称、密码），我们收集这些信息是为了帮助你完成注册。收集手机号码是履行国家法律法规关于网络实名制（真实身份信息认证）要求的必要信息，如果你拒绝提供手机号码用于注册、登录，我们可能无法为你提供信息发布（包括发视频、评论等）功能。
          基于我们与通信运营商的合作，当你使用“一键登录”功能时，经过你的同意，我们会使用运营商提供的功能，将你设备中的手机号码作为你的注册、登录账号并免于密码或动态验证码核验，为你提供快捷的注册、登录服务。如果你不希望使用“一键登录”功能，可以通过其他方式注册、登录抖音。 <br>
          为了让你更安全、便捷地登录抖音，我们在部分设备向你提供刷脸登录服务，登录时你可向我们提供你的脸部图像或视频，该信息仅供与你已留存在平台的信息进行比对，以核验你的身份。该脸部图像或视频属于你的个人敏感信息，我们仅接收验证结果，不存储你在登录过程中提供的脸部图像或视频；如你不需要使用刷脸登录服务，你也可以选择其他登录方式。 <br>
          你可以使用第三方账号注册、登录抖音，但需要授权我们获取你在第三方平台的信息（头像、昵称等公开信息以及你授权的其他信息），用于生成与该第三方账号绑定的抖音账号，使你可以直接注册、登录并使用抖音和相关服务。部分情况下，我们需要重新验证并绑定你的手机号码，以确认该第三方账号的真实性和关联性。 <br>
          如果你忘记了你的抖音的登录方式，可以使用找回密码功能。为保障你账号的安全，我们可能需要你填写相关信息以进行验证，必要时可能通过真实身份信息验证等方式验证你的身份。
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Protocol",
  components: {},
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.Protocol {
  display: flex;
  flex-direction: column;
  align-items: center;
  .iframe-box{
    width: 100%;
    height: 100vh;
    margin-top: 0.2rem;
  }
  .section-wrap {
    display: flex;
    .box-one {
      width: 1.46rem;
      padding-top: 1.3rem;
      .tab-item {
        padding: 0.16rem 0.2rem 0.16rem 0;
        font-size: 0.15rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        .line {
          width: 0.02rem;
          height: 0.13rem;
          background: #00b386;
          margin-left: 0.18rem;
        }
      }
    }
    .box-two {
      width: 10.54rem;
      padding-top: 0.64rem;
      .title {
        text-align: center;
        font-size: 0.22rem;
        color: #333333;
        font-weight: 600;
      }
      .content-box {
        margin-top: 0.46rem;
        font-size: 0.14rem;
        color: #333;
        line-height: .38rem;
      }
    }
  }
}
</style>
