<template>
  <div class="home" @click="hideDialog">
    <header class="head-container" v-if="curPath != '/aboutUs'">
      <div class="head-wrap flex-center" ref="tabwrap">
        <img class="logo-icon" :src="logoIcon" alt />
        <div class="tab-wrap flex-center">
          <div @click.stop="changeTab(i, item)" class="tab-item flex-item-center" style="position: relative;"
            v-for="(item, i) in tabList" :ref="item.id" :key="i">
            <span>{{ item.title }}</span>
            <div v-show="dialogBtn && i == 3" @click.stop
              style="position: absolute;left:50%;bottom:-1.46rem;transform:translateX(-50%);background:#ddd;border-radius: 0.04rem;width:1.4rem;padding:0.05rem 0;z-index:50;">
              <div @click="navLink(0)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">直播公会</div>
              <div @click="navLink(1)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">直播伴侣</div>
              <div @click="navLink(2)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">商户后台</div>
            </div>
            <!-- <div class="tab-line" v-if="tabIndex == i"></div> -->
          </div>
          <div class="tab-line-one animation" ref="base-tab-line" :style="{
            left: lineLeft + 'px',
            transform: `translateX(-${lineWidth / 2}px)`,
          }"></div>
        </div>
      </div>
    </header>
    <div class="head-place" v-if="curPath != '/aboutUs'"></div>
    <div v-if="curPath == '/aboutUs'" class="head-group">
      <div class="head-wrap-new flex-center" ref="tabwrap">
        <img class="logo-icon" :src="logoIcon" alt />
        <div class="tab-wrap flex-center">
          <div @click.stop="changeTab(i, item)" class="tab-item flex-item-center" style="position:relative;"
            v-for="(item, i) in tabList" :ref="item.id" :key="i">
            <span>{{ item.title }}</span>
            <div v-show="dialogBtn && i == 3" @click.stop
              style="position: absolute;left:50%;bottom:-1.46rem;transform:translateX(-50%);background:#ddd;border-radius: 0.04rem;width:1.4rem;padding:0.05rem 0;z-index:50;">
              <div @click="navLink(0)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">直播公会</div>
              <div @click="navLink(1)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">直播伴侣</div>
              <div @click="navLink(2)" style="height: 0.45rem;padding:0 0.2rem;font-size:0.2rem;color:#333;"
                class="flex-item-center">商户后台</div>
            </div>
            <!-- <div class="tab-line" v-if="tabIndex == i"></div> -->
          </div>
          <div class="tab-line-one animation" ref="base-tab-line" :style="{
            left: lineLeft + 'px',
            transform: `translateX(-${lineWidth / 2}px)`,
          }"></div>
        </div>
      </div>
    </div>
    <template>
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </template>
    <!-- <router-view></router-view> -->
    <footer class="footer-wrap">
      <div class="floor-one">
        <div class="text1" @click="navOtherPage('/aboutUs')">关于我们</div>
        <div class="text1">帮助中心</div>
        <div class="text1">加入我们</div>
        <div class="text1" @click="navContactUs">联系我们</div>
        <div class="text1" @click="navOtherPage('/protocol')">法律声明</div>
      </div>
      <div class="floor-two">
        <div class="text1">闪信生活公司</div>
        <div class="text1">版权所有</div>
      </div>
      <div class="floor-three">
        营业执照 | 增值电信业务经营许可证 | 网络文化经营许可证 |
        广播电视节目制作经营许可证
      </div>
      <div class="floor-four">
        <span>www.shanxinlife.com</span>
        <span class="link-box" @click="navFilingLink">陕ICP备2023012978号-3</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tabIndex: 0,
      tabList: [
        { title: "首页", link: "/home", id: "tab-item0" },
        { title: "闪信生活", link: "/home", id: "tab-item1" },
        { title: "社区规范", link: "/home", id: "tab-item2" },
        { title: "商务合作", link: "/home", id: "tab-item3" },
        { title: "联系我们", link: "/contactUs", id: "tab-item4" }
        // { title: "协议", link: "/protocol", id: "tab-item5" },
      ],
      logoIcon: require("/src/assets/image/logoIcon.png"),
      codeIcon: require("/src/assets/image/code.png"),
      appleIcon: require("/src/assets/image/appleIcon.png"),
      androidIcon: require("/src/assets/image/androidIcon.png"),
      bottomIcon: require("/src/assets/image/bottomIcon.png"),
      phoneBanner1: require("/src/assets/image/phoneBanner1.png"),
      circle1: require("/src/assets/image/circle1.png"),
      lineLeft: 0,
      lineWidth: 30,
      curPath: "",
      dialogBtn: false
    };
  },
  methods: {
    hideDialog() {
      this.dialogBtn = false;
    },
    navLink(type) {
      if (type == 0) {
        window.location.href = "http://prodlabor.shanxinlife.com/";
      } else if (type == 1) {
        window.location.href = "http://prodlivemate.shanxinlife.com/";
      } else if (type == 2) {
        window.location.href = "http://prodmeradmin.shanxinlife.com/";
      }
    },
    testVant() { },
    changeTab(index, item) {
      console.log(item.title,'item.title')
      if (item.title == "商务合作") {
        console.log(1111)
        this.dialogBtn = true;
        return;
        window.location.href = "http://prodlabor.shanxinlife.com/";
      } else {
        this.tabIndex = index;
        console.log(item.link, "item.link");
        this.initTabData();
        this.$nextTick(() => {
          this.$router.push({ path: item.link });
          this.curPath = this.$route.path;
        });
        this.hideDialog();
      }
    },
    navContactUs() {
      console.log("跳转");

      this.tabIndex = 4;
      this.initTabData();
      this.$router.push({ path: "/contactUs" });
      this.$nextTick(() => {
        this.curPath = "/contactUs";
        this.$forceUpdate();
      });
    },
    initTabData() {
      let leftWith = 0;
      let currentWidth = 0;
      for (let i = 0; i < this.tabList.length; i++) {
        let itemRef = this.$refs[this.tabList[i].id];
        console.log(itemRef[0].offsetWidth, "itemRef");
        if (i < this.tabIndex) {
          leftWith += itemRef[0].offsetWidth;
        } else if (i == this.tabIndex) {
          currentWidth = itemRef[0].offsetWidth;
        }

        // console.log(.offsetWidth,'itemSty')
      }
      //line宽度
      console.log(this.$refs["base-tab-line"], 'this.$refs["base-tab-line"]');
      this.lineWidth = this.$refs["base-tab-line"].offsetWidth;
      // 滑块作移动的位置
      this.lineLeft = leftWith + currentWidth / 2;
    },
    navOtherPage(url) {
      this.tabIndex = null;
      this.lineLeft = -100;
      this.$router.push({ path: url });
      this.$nextTick(() => {
        this.curPath = url;
        this.$forceUpdate();
      });
    },
    navFilingLink() {
      console.log(11111);
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    }
  },
  created() {
    let path = this.$route.path;
    console.log(path, "path");
    this.curPath = path;
  },
  mounted() {
    this.initTabData();
    window.onresize = () => {
      setTimeout(() => {
        this.initTabData();
      }, 300);
    };
  }
};
</script>
<style lang="scss" scoped>
.home {
  padding-bottom: 0.48rem;
}

.animation {
  transition: all 0.3s linear;
}

.head-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 0.72rem;
}

.head-place {
  height: 0.72rem;
}

.head-wrap {
  padding: 0 0.34rem;
  height: 0.72rem;
  background: #00b386;

  .logo-icon {
    width: 0.42rem;
    height: 0.38rem;
    margin-right: 0.4rem;
  }

  .tab-wrap {
    position: relative;

    .tab-item {
      height: 0.72rem;
      padding: 0 0.18rem;
      font-size: 0.24rem;
      color: #fff;
      font-weight: 500;
      position: relative;
      cursor: pointer;
      width: auto;

      .tab-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0.48rem;
        height: 0.03rem;
        background: #ffffff;
        bottom: 0;
      }
    }

    .tab-line-one {
      position: absolute;
      width: 0.48rem;
      height: 0.03rem;
      background: #ffffff;
      bottom: 0;
    }
  }
}

.head-group {
  width: 100%;
  height: 5.72rem;
  background: url(../../assets/image/aboutSxBg.png) no-repeat 100% 100%;
  background-size: 100% 100%;

  .head-wrap-new {
    padding: 0 0.34rem;
    height: 0.72rem;
    background: transparent;

    .logo-icon {
      width: 0.42rem;
      height: 0.38rem;
      margin-right: 0.4rem;
    }

    .tab-wrap {
      position: relative;

      .tab-item {
        height: 0.72rem;
        padding: 0 0.18rem;
        font-size: 0.24rem;
        color: #fff;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        width: auto;

        .tab-line {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0.48rem;
          height: 0.03rem;
          background: #ffffff;
          bottom: 0;
        }
      }

      .tab-line-one {
        position: absolute;
        width: 0.48rem;
        height: 0.03rem;
        background: #ffffff;
        bottom: 0;
      }
    }
  }
}

// .fade-enter-active, .fade-leave-active {transition: opacity .2s;
// }.fade-enter, .fade-leave-to {opacity: 0;
// }
.footer-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.74rem;

  .floor-one {
    display: flex;
    align-items: center;
    justify-content: center;

    .text1 {
      cursor: pointer;
      padding: 0.1rem 0.14rem;
      color: #000000;
      font-size: 0.26rem;
      font-weight: 500;
    }
  }

  .floor-two {
    display: flex;
    align-items: center;
    margin-top: 0.38rem;
    font-size: 0.14rem;
    color: #979797;

    .text1 {
      padding: 0 0.03rem;
      color: #979797;
      font-size: 0.2rem;
    }
  }

  .floor-three {
    font-size: 0.22rem;
    color: #979797;
    margin: 0.07rem 0;
    padding: 0 0.4rem;
  }

  .floor-four {
    font-size: 0.2rem;
    color: #979797;

    .link-box {
      cursor: pointer;
    }
  }
}

.content-box {
  width: 9.6rem;
  height: 50px;
  background: #faf;
  color: #fff;
  text-align: center;
}
</style>
