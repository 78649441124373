<template>
  <div class="Protocol">
    <div class="section-wrap">
      <!-- <span class="box1">闪信生活是集社交、娱乐、创作（交流）、评论于一体的综合类软件平台，在闪信生活平台中您将与生活中的美好惬意相遇、相识、相知。</span> -->
      <p class="inner1">闪信生活是集社交、娱乐、创作（交流）、评论于一体的综合类软件平台，在闪信生活平台中您将与生活中的美好惬意相遇、相识、相知。</p>
      <p class="inner1">闪信生活为每位客户开启了一个高效有趣的社交门户途径，闪信生活开发的最初出发点亦在于此。人类社会步入现代化社会，社交是群体生活的必需品，现在的日常生活已经被数字化了，保持与生存物资、游戏娱乐资源、知识信息源、社会热点新闻等时刻信息铰链，生活会因闪信生活的使用而顺畅、惬意、丰富、多彩，生活才会更有趣，才能享受到现代社会信息爆炸式数字化传播的带来的便捷高效；社交是精神层面的需要和渴求，与他人沟通、共情、交换信息，才能避免个体与个体、个体与群体的断联，客户因交联而延展生活的宽度，因共情而加深生活的深度，因信息互换而丰富生活的色彩，反之，很容易想象精神孤岛会让人感到多么的悲催和绝望；闪信生活有传输超能力，就如同客户在古时配备了信鸽、在近代配备了话报机、在当代的配备了智能手机，依托语音、视频、数据、图片、文字等传递信息，如实、迅疾的为客户服务，达成您赋予它的使命任务。</p>
      <br />
      <p class="inner1">闪信生活为客户随时贡献了沉浸式尊享的娱乐盛宴。在闪信生活平台上，闪信生活共提供了以下娱乐方式：悦享短视频（音频），闪信生活平台创作并上传有意义、有价值、正能量、轻松惬意的视音频作品，用户可创作独有的各类型作品，上传经审核后发布于平台，用户可以以多种身份（客户、游客、创作者、直播主播、直播室观众、朋友圈好友、附近的人、动态关注者等）浏览、悦享海量视音频资源，以丰富您的生活、感悟您的生活、共享他人的美好、分享他人的心境、悦赏自然大美、聆听世间所有的动听等；欢享视（音）频直播，闪信生活平台向注册视频号的用户开放了直播端口，多才多艺的用户可开直播，展现个人才能同时赚取打赏收益，不负青春，不负平台，不负天生的多才多艺，也可使钱包越来越鼓，何乐而不为；交流欣赏追（影视）剧等，闪信生活平台设置了“频道”端口，与第三方合作提供诸如实时发生（热点）类、直播（转播）类、影视类等视音频资源，用户在付费后或授权后即可随时、专注、全剧集的欣赏各类个人关注的节目，娱乐身心，放松自己；分享生活中的美好瞬间，闪信生活平台提供了朋友圈、动态、附近的人等展示窗口，客户可以在个人认为适当时机、合适场景、合理事由、合法途径、合情逻辑、不损害他人权益、不伤害看众审美和感情的情景下，随时分享个人所得、个人所悟、个人所见，让关注自己、关心自己、牵挂自己的其他客户时刻能共享，缩短时空对我们的禁锢；诸如此类等等。</p>
      <br />
      <p class="inner1">闪信生活平台延续了当今时髦的带货赚收益渠道。带货赚收益
      ，闪信生活用户粉丝量到预定数量，可申请于朋友圈、动态的展示区域附带拟推荐货品之小黄车供粉丝们挑选、购买，或开启带货直播，在个人直播室向“宝子们”推荐货品，与观众分享优质货物，共享生活之美好；商城售货赚收益，闪信生活平台开发了购物商城，延续继承了电商赚钱的模式，有经营电商意愿的客户可以申请入驻平台商城，用您质优价廉的物品打动平台上的客户，从而赚取应得收益，在平台上成就您的一番发家致富愿景。</p>
      <br />
      <p class="inner1">闪信生活构建了创作（交流）、评论的网络社区，为诸多用户筹建了个公平正义和温馨关爱的精神家园。闪信生活平台开辟了客户创作作品发布的展示区域，如直播室、朋友圈、动态、附近等，可借助文字、话语、图片等方式，客户大众可以合规合法合情的发表自己的看法、感情、认识，评论个人关心的人物、事件、物品，共情个人所体悟所感触，抱团抒发情绪，当然，这一切必须合法合规、合情合理，且严守个人道德底线、社会公序良俗、法律规章之规定等。</p>
      <br />
      <p class="inner1">宝剑锋从磨砺出，梅花香自苦寒来。闪信生活的诞生经历了灵感触发、酝酿构思、培育萌发、瓜熟蒂落，概莫能外的与众多的软件研发经历一致，而前述的肆方面研发愿景就是最初的出发点。</p>
      <br>
      <p class="inner1">相对于现今流行的几大平台给予我们生活丰富多才、方便快捷的同时，闪信生活平台有如下的优势，如：</p>
      <br>
      <p class="inner1">1.由于是不同持有者研发的软件，例如T宝、M团、D音、W信、小H书等，兼容性较差，会在个人手机中叠加安装，拖慢手机运行速度，正运行中的程序被中断后原场景复原性太差，多款软件就有多个圈子/多个聊天群造成寻找关注的人和事困难，而闪信生活完美避开了以上不足；</p>
      <br>
      <p class="inner1">2.AI机器人应用是近阶段科技进步的亮点，在闪信生活平台中内置AI机器人顺应了社会的进步，也是一种有益的尝试，虽然当前来看AI机器人要达到至臻至美的境地还有段路要走，但只要迈开了步子我们就在进步；</p>
      <br>
      <p class="inner1">3.新增了互关按钮，个人私域和公众公域严格区分，同时有条件的控制的转换，闪信生活有别于其他软件的感受到了客户的痛点，不再使客户受到莫名其妙的干扰，社交、生活、工作予以区分划圈，避免混乱或越界；</p>
      <br>
      <p class="inner1">4.群聊有助于工作、生活的快捷，但当天提供该项服务功能的软件均有群聊人数的限制，多有不便，而闪信生活感受的用户的这一痛点，有偿的提供大群、特大群、超大群的建立和维护，满足特殊用户群体的特殊需求；</p>
      <br>
      <p class="inner1">5.闪信生活对礼物应用实施了场景的延展和优化，预设了礼物背包功能，由于闪信生活集合聊天、直播、商城等于一体，将好友、粉丝、陌生人等奉送的礼物可进行应用场景转化，用于打赏、转赠等，更灵活的使礼物成为个人悦赏的“礼物”，赋予了礼物中“情义、价值”延续和优化；</p>
      <br>
      <p class="inner1">6.新增会员权重模式，客户可以有偿的获取闪信生活平台的道具，彰显特有身份，且享有约定的权益，满足部分客户的心理需求，增大闪信生活平台的受众面。</p>
      <br>
      <p class="inner1">至此，我们就将闪信生活的基本服务功能和特有优点向您展示了大部，其他的潜在服务或功能就要在使用中不断发现了，在此就不赘述了。</p>
      <br>
      <p class="inner1">我们是新生代的互联网爱好者，对网络有自己的看法，对互联网文化有独到的认识，对生活也有自己特有的观察，所以我们研发了闪信生活。</p>
      <br>
      <p class="inner1">未来我们将努力求真，紧紧把握时代和科技的脉搏，用心聆听客户改进意见建议，着力促使闪信生活朝着更好用、更实用、更美好、永远可依赖的方向至臻至美的发展，以期实现如下愿景：</p>
      <br>
      <p class="inner1">闪信生活应该是更完善、更顺畅、更可靠、更可依赖的。事情往往就是这样，当我们认真的对待一件事时，就能发现它时多么的不完美、多么的需要改善，而这正是事务不断向前发展的内驱之因，我们也一直并将持续的这样认为，从而竭尽所能，用心聆听每位客户的心声并列入我们的工作中予以落实之。</p>
      <p class="inner1">闪信生活应是更智能、更人性、更科技的。人类之所以延续至今，发展出超越其他物种的文明，智慧智能是重要之因，闪信生活虽然仅是一款软件，但是也应该吸收更多的人类智慧、人类科技而不断进化，从而使其更贴近人性的需要、更贴合科技发展的脉络、更智能的服务于人，闪信生活致力于此方向发展。</p><br>
      
      <br>
      <p class="inner1">闪信生活应是人人各得其乐的平台。古语云：有容乃大。大海之所以成其为大海，是因为它吸收了众多的小河、大河、大洋，汇聚成大海。同理，闪信生活要成长壮大，也要容众多软件平台的优长、戒众多软件平台的弊病，融合形形色色的客户的使用需求、意见、建议、批评、褒扬，方能人人在此平台上找到各自的快乐。</p>
      <br>
      <p class="inner1">关于我们，就向您介绍这么多了。</p>
      <br>
      <p class="inner1">谢谢。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.Protocol {
  display: flex;
  flex-direction: column;
  align-items: center;

  .section-wrap {
    width: 12rem;
    padding-top: 0.6rem;
    font-size: 0.2rem;
    color: #4c4c4c;
    font-weight: 500;
    line-height: 0.4rem;
    text-indent: 0.8rem;
    .box1 {
      font-size: 0.2rem;
      color: #131313;
      font-weight: 600;
      display: block;
      margin-bottom: 0.2rem;
    }
  }
}</style>
