var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",on:{"click":_vm.hideDialog}},[(_vm.curPath != '/aboutUs')?_c('header',{staticClass:"head-container"},[_c('div',{ref:"tabwrap",staticClass:"head-wrap flex-center"},[_c('img',{staticClass:"logo-icon",attrs:{"src":_vm.logoIcon,"alt":""}}),_c('div',{staticClass:"tab-wrap flex-center"},[_vm._l((_vm.tabList),function(item,i){return _c('div',{key:i,ref:item.id,refInFor:true,staticClass:"tab-item flex-item-center",staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.changeTab(i, item)}}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogBtn && i == 3),expression:"dialogBtn && i == 3"}],staticStyle:{"position":"absolute","left":"50%","bottom":"-1.46rem","transform":"translateX(-50%)","background":"#ddd","border-radius":"0.04rem","width":"1.4rem","padding":"0.05rem 0","z-index":"50"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(0)}}},[_vm._v("直播公会")]),_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(1)}}},[_vm._v("直播伴侣")]),_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(2)}}},[_vm._v("商户后台")])])])}),_c('div',{ref:"base-tab-line",staticClass:"tab-line-one animation",style:({
          left: _vm.lineLeft + 'px',
          transform: `translateX(-${_vm.lineWidth / 2}px)`,
        })})],2)])]):_vm._e(),(_vm.curPath != '/aboutUs')?_c('div',{staticClass:"head-place"}):_vm._e(),(_vm.curPath == '/aboutUs')?_c('div',{staticClass:"head-group"},[_c('div',{ref:"tabwrap",staticClass:"head-wrap-new flex-center"},[_c('img',{staticClass:"logo-icon",attrs:{"src":_vm.logoIcon,"alt":""}}),_c('div',{staticClass:"tab-wrap flex-center"},[_vm._l((_vm.tabList),function(item,i){return _c('div',{key:i,ref:item.id,refInFor:true,staticClass:"tab-item flex-item-center",staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.changeTab(i, item)}}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogBtn && i == 3),expression:"dialogBtn && i == 3"}],staticStyle:{"position":"absolute","left":"50%","bottom":"-1.46rem","transform":"translateX(-50%)","background":"#ddd","border-radius":"0.04rem","width":"1.4rem","padding":"0.05rem 0","z-index":"50"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(0)}}},[_vm._v("直播公会")]),_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(1)}}},[_vm._v("直播伴侣")]),_c('div',{staticClass:"flex-item-center",staticStyle:{"height":"0.45rem","padding":"0 0.2rem","font-size":"0.2rem","color":"#333"},on:{"click":function($event){return _vm.navLink(2)}}},[_vm._v("商户后台")])])])}),_c('div',{ref:"base-tab-line",staticClass:"tab-line-one animation",style:({
          left: _vm.lineLeft + 'px',
          transform: `translateX(-${_vm.lineWidth / 2}px)`,
        })})],2)])]):_vm._e(),[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],_c('footer',{staticClass:"footer-wrap"},[_c('div',{staticClass:"floor-one"},[_c('div',{staticClass:"text1",on:{"click":function($event){return _vm.navOtherPage('/aboutUs')}}},[_vm._v("关于我们")]),_c('div',{staticClass:"text1"},[_vm._v("帮助中心")]),_c('div',{staticClass:"text1"},[_vm._v("加入我们")]),_c('div',{staticClass:"text1",on:{"click":_vm.navContactUs}},[_vm._v("联系我们")]),_c('div',{staticClass:"text1",on:{"click":function($event){return _vm.navOtherPage('/protocol')}}},[_vm._v("法律声明")])]),_vm._m(0),_c('div',{staticClass:"floor-three"},[_vm._v(" 营业执照 | 增值电信业务经营许可证 | 网络文化经营许可证 | 广播电视节目制作经营许可证 ")]),_c('div',{staticClass:"floor-four"},[_c('span',[_vm._v("www.shanxinlife.com")]),_c('span',{staticClass:"link-box",on:{"click":_vm.navFilingLink}},[_vm._v("陕ICP备2023012978号-3")])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-two"},[_c('div',{staticClass:"text1"},[_vm._v("闪信生活公司")]),_c('div',{staticClass:"text1"},[_vm._v("版权所有")])])
}]

export { render, staticRenderFns }