import request from '@/utils/request'


// 登录
export function selOneVersion(data) {
  return request({
    url: `/auth/admin/version/selOneVersion`,
    method: 'post',
    contentType:'application/x-www-form-urlencoded',
    data
  })
}

